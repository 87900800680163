import React from "react";
import "./MobileView.css";
import GooglePlay from "../../../svgIcons/redirection/GooglePlay_Bictorys.svg";
import AppStore from "../../../svgIcons/redirection/Appstore_Bictorys.svg";
import LogoBictorys from "../../../svgIcons/redirection/Logo_Bictorys.svg";

const MobileView: React.FC = () => {
  return (
    <div className="mobile-view">
      <div className="mobile-view_container">
        <div className="mobile-view_card">
          <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: 30}}>
            <div className="mobile-view_logo">
              <img
                src={LogoBictorys}
                alt="Bictorys logo"
              />
            </div>
            <div className="mobile-view_text">
              <h1 style={{fontSize: 20, margin:0}}>Optimisez votre expérience !</h1>
              <p style={{fontSize: 14, margin:0, width: "264px"}}>
                Pour une navigation fluide et une meilleure accessibilité,
                téléchargez notre application mobile dès maintenant.
              </p>
            </div>
          </div>
          <div className="mobile-view_download">
            <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", color:"white", position: "relative"}}>
              <div className="download-button">
                <span>Télécharger l'application</span>
              </div>
              <div className="download-button-arrow"></div>
            </div>
            <div className="download-button_store">
              <a href="https://play.google.com/store/apps/details?id=com.bictorysmobile">
                <img src={GooglePlay} alt="GooglePlay app" />
              </a>
              <a href="https://apps.apple.com/us/app/bictorys/id6657954063" >
                <img src={AppStore} alt="AppStore app" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
